<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlSjdrSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" @handleCommand="handleCommand"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <!-- <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </el-col> -->
        <el-col :span="24" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :isSjdr="true"></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>
    
    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform" @uploadSuccess="uploadSuccess" @cancelDialog="cancelDialog" @confirmDialog="confirmDialog" :isUpload='true' :isSpanAll="true"></general-form>


    <!-- 模板上传 -->
    <general-form :FormDialog="uploadDialog" :FormList="uploadFormList" :title="uploadTitle" :FormData="uploadform" @uploadSuccess="uploadSuccessUpload" @cancelDialog="cancelDialogUpload" @confirmDialog="confirmDialogUpload" :isUploadModel='true' :isSpanAll="true"></general-form>

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  import { getToken } from '@/utils/auth'
  export default {
    name: 'hdybsjdr',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        jsonData:[//table数据
          {serialNumber:1,state:'成功',taskName:'水表导入1',importType:'批量导入',fileName:'水表导入1',successRate:'100%',creatTime:'2022-8-8 09:55:51',endTime:'2022-8-9 13:56:13',fileSize:'200',projectOrganization:'',userNumber:'9839871987381',}
        ],
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          key: undefined
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          taskName:'',
          importType:'SYS_UploadFileType_BatchLoading',
          projectCode:'',
          fileName:'',//上传文件名
        },
        tokenHeader: {'X-Token': getToken()},
        fileList:[],//上传列表
        FormList:{//表格列
          column:[
            {columnKey:'taskName',columnDescription:'任务名称',columnType:'text',prop:'taskName',width:'120',placeholder:"任务名称",},
            {
              columnKey:'importType',
              columnDescription:'导入类型',
              columnType:'select',
              placeholder:"请选择导入类型",
              prop:'importType',
              width:'120',
              typeId:'SYS_UploadFileType',//从全局数据字典里获取下拉数据
            },
            // {
            //   columnKey:'projectCode',
            //   columnDescription:'所属项目',
            //   columnType:'selectProject',
            //   placeholder:"请选择所属项目",
            //   prop:'projectCode',
            //   width:'120',
            // },
            {columnKey:'fileName',columnDescription:'文件',columnType:'upload',prop:'fileName',width:'120',placeholder:"请选择上传文件",},
          ],
          //校验
          rule:{
            taskName: [{ required: true, message: "任务名称不能为空", trigger: "blur" }, ],
            // projectCode: [{ required: true, message: "所属项目不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'创建任务',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        uploadDialog:false,//模板上传
        uploadTitle:'模板上传',//模板上传标题
        uploadFormList:{//模板上传表格列
          column:[
            {
              columnKey:'TemplateType',
              columnDescription:'模板类型',
              columnType:'select',
              placeholder:"请选择模板类型",
              prop:'TemplateType',
              width:'120',
              typeId:'Hdyb_TemplateType',//从全局数据字典里获取下拉数据
            },
            
            {columnKey:'Files',columnDescription:'文件',columnType:'upload',prop:'Files',width:'120',placeholder:"请选择上传文件",},
          ],
          //校验
          rule:{
            taskName: [{ required: true, message: "任务名称不能为空", trigger: "blur" }, ],
          }
        },
        uploadform:{
          TemplateType:'BatchInstallationTemplate',
          Files:'',//上传文件名
        },
        
      }
    },
    watch: {

    },
    computed: {
      //左侧树
      orgsTree() {
        return this.$store.state.AllArea;
      },
      
    },
    filters: {
      
    },
    created() {
      this.getList()
      
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'download'://下载模板
            this.downloadModel()
            break
          case 'modelUpload'://模板上传
            this.uploadDialog = true
            break
          case 'btnDel'://删除
            if(this.tableChooseRow.id){
              this.delete(this.tableChooseRow)
            }else{
              this.$message.error('请先选中表格中的某一记录！');
            }
            break
          default:
            break
        }
      },
      // 下拉菜单事件
      handleCommand(val){
        console.log(val,33)
        if(this.tableChooseRow.id){
          switch (val.parameter) {
            case '源文件':
              this.exportFile(val.command)
              break;
            case '成功文件':
              this.exportFile(val.command)
              break;
            case '失败文件':
              this.exportFile(val.command)
              break;
          }
        }else{
          this.$message.error('请先选中表格中的某一记录！');
        }
      },
      //导出文件
      exportFile(outType){
        var data={
          outType:outType,
          id:this.tableChooseRow.id
        }
        basicDataApi.sjdrExport(data).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/' + response.result
            window.open(url)
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.sjdrGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            this.loading = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
            this.loading = false
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
        //  this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗上传成功
      uploadSuccess(val){
        this.addform.uploadFilsName = val
      },
      // add弹窗提交
      confirmDialog(){
        this.addform={
          taskName:'',
          importType:'SYS_UploadFileType_BatchLoading',
          fileName:'',//上传文件名
          projectCode:'',
        }
        this.FormDialog = false
        this.getList()
      },
      // 下载模板
      downloadModel(){
        basicDataApi.sjdrDownModel({TemplateType:'BatchInstallationTemplate'}).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/' + response.result
            window.open(url)
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      // 模板上传弹窗取消
      cancelDialogUpload(val){
        this.uploadDialog = val
      },
      // 模板上传弹窗上传成功
      uploadSuccessUpload(val){
        this.uploadform.Files = val
      },
      // 模板上传弹窗提交
      confirmDialogUpload(){
        this.uploadform={
          TemplateType:'BatchInstallationTemplate',
          Files:'',//上传文件名
        }
        this.uploadDialog = false
        this.getList()
      },
      //删除
      delete(row){
        var s = []
        s.push(row)
        basicDataApi.sjdrDel(s).then(response => {
          if(response.code == 200){
            this.getList()
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
